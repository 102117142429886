<template>
  <v-layout wrap justify-center style="font-family: 'Nunito Sans', sans-serif">
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <v-flex xs12 sm10 lg10 py-6>
      <v-card class="mx-auto elevation-2" align="center" outlined>
        <v-layout xs10 wrap class="d-flex px-4 mt-6 mx-0">
          <v-flex
            xs12 sm6
            lg8
            class="text-left lg-0 lg-pl-6"
            style="font-weight: 700; font-size: 23px"
          >
            <v-icon class="pr-4" color="#9A8CFC">mdi-school-outline</v-icon
            >Student List
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs12 sm6 lg4 xl4 align-self-center text-right>
            <v-text-field
              v-model="keyword"
              clearable
              hide-details
              label="Search Name/Ad.No"
              outlined
              class="rounded-xl"
              dense
              @input="search"
            >
            </v-text-field>
          </v-flex>
          <!-- <v-flex xs12 sm6 lg2 xl1 pl-2 pt-2 pt-lg-0 align-self-center text-right>
            <v-btn
            block
            class="rounded-xl"
              @click="search()"
              style="font-family: Nunito Sans, SemiBold"
              color="#766BC0"
              dark
              dense
              depressed
              >SEARCH</v-btn
            >
          </v-flex> -->
          <!-- <v-flex
            xs12 lg6
            class="text-left"
            style="font-weight: 400; color: #797979; font-size: 20px"
          >
            <v-icon
              class="pr-4"
              @click="$router.push({ path: '/newstudent' })"
              >mdi-account-plus</v-icon
            >Add New Students
          </v-flex> -->
        </v-layout>
        <!-- <v-flex xs12 class="d-flex pa-6 mx-0" outlined> -->
        <v-layout wrap justify-space-around pa-3  mt-2>
          <v-flex xs12 md6 lg3 pa-2  align-self-center>
            <v-select
              :items="classList"
              v-model="cls"
              label="Class"
              item-text="standardName"
              item-value="_id"
              clearable
              hide-details
              outlined
              @input="search()"
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 align-self-center md6 lg3 pa-2  >
            <v-select
              :items="divisionList"
              v-model="div"
              clearable
              hide-details
              label="Division"
              item-text="divisionName"
              item-value="_id"
              outlined
              @input="search()"
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 md6 align-self-center lg3 pa-2 >
            <v-select
              :items="SchoolList"
              v-model="school"
              label="School"
              item-text="SchoolName"
              item-value="_id"
              clearable
              hide-details
              outlined
              @input="search()"
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 md6 align-self-center lg3 pa-2 >
            <v-select
              :items="HostelList"
              v-model="hostel"
              label="Hostel"
              item-text="hostelName"
              item-value="_id"
              clearable
              hide-details
              outlined
              @input="search()"
              dense
            ></v-select>
          </v-flex>
          
        </v-layout>
        <v-layout wrap justify-start v-if="allData.length > 0">
          <v-flex xs12 sm6 lg4 pa-4 v-for="(item, i) in allData" :key="i">
            <v-card :elevation="0" class="py-2">
              <v-layout wrap>
                <!-- <v-flex xs2 class="py-4">
                  <p
                    class="ma-0"
                    style="font-size: 16px; color: #9d9d9d; font-weight: 600"
                  >
                    {{ 50 * (page - 1) + (i + 1) }}
                  </p>
                </v-flex> -->
                <v-flex xs3 class="py-2">
                  <v-avatar>
                    <img
                      :src="`https://picsum.photos/id/${i}/200/300`"
                      alt="John"
                      class="pa-1"
                    />
                  </v-avatar>
                </v-flex>
                <v-flex xs7 text-left pl-2 class="py-4">
                  <router-link
                    style="text-decoration: none"
                    :to="'/studentclothes?id=' + item._id"
                  >
                    <span
                      style="font-size: 16px; font-weight: 400; color: #9d9d9d"
                    >
                      {{ item.name }} <br />
                      <span style="font-size: 14px">
                        <b> Ad.No {{ item.admissionNo }} </b>
                      </span>
                    </span>
                  </router-link>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs12 md6>
            <v-pagination
              small
              v-model="page"
              :total-visible="7"
              :length="Pagelength"
            >
            </v-pagination>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
// import store from "../store";
import axios from "axios";
export default {
  data() {
    return {
      HostelList: [],
      SchoolList: [],
      page: 1,
      Pagelength: 0,
      allData: [],
      div: null,
      cls: null,
      hostel: null,
      school: null,
      appLoading: false,
      classList: null,
      divisionList: null,
      keyword: null,
    };
  },
  watch: {
    cls() {
      this.page = 1;
      this.getDivision();
    },
    div() {
      this.page = 1;

      if (this.cls && this.div) this.search();
    },
    page() {
      this.search();
    },
    hostel() {
      this.page = 1;

      this.search();
    },
  },
  beforeMount() {
    this.search();
    this.gethostel();
    this.getschool();
    this.getclass();
    if (this.cls) this.getDivision();
  },
  methods: {
    getclass() {
      axios({
        method: "get",
        url: "/standard/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.classList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getDivision() {
      axios({
        method: "get",
        url: "/division/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          id: this.cls,
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.divisionList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    gethostel() {
      axios({
        method: "get",
        url: "/main/hostel/name/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.HostelList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    getschool() {
      axios({
        method: "get",
        url: "/school/getlist",
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          if (response.data.status == true) {
            this.SchoolList = response.data.data;
          }
        })
        .catch((err) => {
          console.log(err());
        });
    },
    search() {
      this.allData = [];
      this.appLoading = true;
      axios({
        method: "get",
        url: "/student/getalllist/v1",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          division: this.div,
          standard: this.cls,
          page: this.page,
          limit: 50,
          keyword: this.keyword,
          hostelname: this.hostel,
          school: this.school,
        },
      })
        .then((response) => {
          // this.page=1;
          this.appLoading = false;
          if (response.data.status == true) {
            this.allData = response.data.data;
            this.name = response.data.name;
            this.Pagelength = Math.ceil(response.data.totalLength / 50);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err());
        });
    },
  },
};
</script>